import { getCommonParamsItemLabel } from '@/utils/common'

export const contentTableConfig = {
  // title: '('common.app-application-list')',
  title: '',
  propList: [
    {
      prop: 'versionCode',
      label: 'file.version-code'
    },
    {
      prop: 'versionName',
      label: 'file.version-name'
    },
    {
      prop: 'downloadCount',
      label: 'file.download-counts'
    },
    {
      prop: 'signed',
      label: 'file.is-sign'
    },
    {
      prop: 'status',
      label: 'general.status',
      width: '100',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'status')
      }
    },
    {
      prop: 'size',
      label: 'cardbin.size',
      width: '120',
      formatter: (row) => {
        return (row / (1024 * 1024)).toFixed(2) + 'MB'
      }
    },
    {
      prop: 'appEnvType',
      label: 'file.app-env',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'app_env_type')
      }
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time',
      width: '170'
    },
    {
      prop: 'options',
      label: 'common.app-operation',
      width: '320',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true,
  showColSetting: true,
  tableColCheckedListName: 'applicationFileTableCheckedCol',
  tableColAllListName: 'applicationFileTableAllCol'
}
