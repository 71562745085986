import { basicRequiredRule } from '@/template/rule'
import { getCommonParamsOptions, getParamsOptionsAsync } from '@/utils/common'

export const baseNewFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'file.application-name',
      otherOptions: {
        maxlength: 80
      }
    },
    {
      field: 'author',
      type: 'input',
      label: 'file.author',
      otherOptions: {
        maxlength: 100
      }
    },
    {
      field: 'company',
      type: 'input',
      label: 'file.company',
      otherOptions: {
        maxlength: 200
      }
    },

    {
      field: 'appType',
      type: 'select',
      label: 'file.application-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('app_type')
    },
    {
      field: 'tenantWhitelist',
      type: 'treeSelect',
      label: 'Tenant Whitelist',
      isResolveGlobalParams: true,
      otherOptions: {
        maxlength: 1000
      },
      handler: () =>
        getParamsOptionsAsync('tenantList', {
          isNeedPreFix: false
        }),
      options: []
    },

    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        maxlength: 500
      }
    },
    {
      field: 'iconUrl',
      type: 'imgUpload',
      label: 'common.app-icon',
      uploadTip: 'file.icon-upload-tip',
      otherOptions: {
        uploadApiAction: 'system/uploadImgFile'
      }
    },
    {
      field: 'screenShotUrl',
      type: 'pictureList',
      label: 'common.app-screen-shots',
      colLayout: {
        xl: 16,
        lg: 16,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        uploadApiAction: 'system/uploadImgFile'
      }
    }
  ],
  validateRules: {
    name: [basicRequiredRule],
    appType: [basicRequiredRule],
    author: [basicRequiredRule],
    company: [basicRequiredRule],
    iconUrl: [basicRequiredRule]
  }
}
